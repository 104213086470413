body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: white;
}


@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.9);
    opacity: 0.7;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cyclobrowsing {
  font-family: "IBM Plex Mono", mono;
  font-weight: 500;
  letter-spacing: 0.3px;
  --red: #ec1556;
  --gray-lighter: #efefef;

  ::selection {
    background: var(--red);
    color: white;
  }

  a {
    color: var(--red);
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  .container {
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  .map {
    height: inherit;
    width: inherit;
  }

  .today {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    margin: 20% auto;
    -webkit-animation: pulse 3s infinite ease-in-out;
    -o-animation: pulse 3s infinite ease-in-out;
    -ms-animation: pulse 3s infinite ease-in-out;
    -moz-animation: pulse 3s infinite ease-in-out;
    animation: pulse 3s infinite ease-in-out;
  }

  .sidebar {
    position: absolute;
    top: 0;
    left: 0;
    width: 400px;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: -4px -5px 50px -1px rgba(0, 0, 0, 0.2);
    box-shadow: -4px -5px 50px -1px rgba(0, 0, 0, 0.2);
  }

  .clickable-invisible-sidebar {
    position: absolute;
    left: 350px;
    width: 50px;
    height: 100%;
    cursor: pointer;
  }

  .bottom-info {
    position: absolute;
    /* padding-top: 85vh; */
  }

  .info-container {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: background-color 250ms;
  }

  .info-container:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .info-content {
    pointer-events: auto;
    overflow-y: auto;
    position: absolute;
    width: 50%;
    top: 0;
    left: 50%;
    font-size: 13px;
  }

  .info-content:before {
    position: absolute;
    top: 0;
    left: 0;
  }

  .info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-top: 1px solid var(--red);
  }

  .footer.mobile {
    display: none;
  }

  .footer {
    position: absolute;
    bottom: 0;
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .footer-text {
    font-weight: 400;
    font-size: 12px;
    height: 12px;
    align-self: center;
    display: inline;
    padding-right: 1rem;
  }

  .loader {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    align-self: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--red);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--red) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  .info-content-text {
    padding: 0 30px;
    color: var(--red);
  }

  .cyclobrowsing-logo {
    height: 100px;
    width: calc(100% - 1rem);
  }

  .stats {
    padding-top: 2rem;
    width: 50%;
    min-width: 280px;
  }

  .title {
    position: absolute;
    font-family: "My Soul";
    top: -30px;
    left: 20px;
    font-size: 150px;
    margin: 0;
    color: var(--red);
  }

  .carousel {
    position: fixed;
    width: 50%;
    height: 100%;
    left: 50%;
    top: 0;
    overflow-y: scroll;
  }

  .carousel img {
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .carousel-mobile {
    display: none;
  }
}

.home {
  width: 100%;
  height: 100%;
  font-family: "National Park";
  --blue: #00A3FF; 




  a {
    color: white;
  }

  ::selection {
    background: var(--blue);
    color: white;
  }


  .container {
    position: fixed;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: hidden;
  }


  .flying-igor {
    position: absolute;
    right:0;
    bottom: 5rem;
    text-decoration: none;
    transition: transform 500ms;
  }

  .flying-igor:hover {
    transform: translate(0, -10px);
  }
  
  .flying-igor-image {
    height: 200px;
    transform: scaleX(-1)
  }

  .where-are-you {
    color: var(--blue);
    font-size: 18px;
    transform: rotate(10deg);
  }

  .carousel {
    width: 700px;
    height: 100%;
    position: absolute;
    z-index: 2;
    left: 2rem;
    top: 0;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .carousel-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .carousel-title {
    position: fixed;
    top: 4rem;
    left: 4rem;
    z-index: 1;
    height: 50px;
    max-width: calc(700px - 8rem);
    object-fit: contain;
  }
  
  .carousel-x {
    position: fixed;
    top: 4.5rem;
    left: calc(700px - 2.5rem);
    z-index: 1;
    height: 50px;
    cursor: pointer;
  }

  .carousel-content {
    padding-top: 1rem;
    padding-bottom: 5rem;
    color: var(--blue);
    font-weight: 500;
  }

  .carousel-image {
    border-radius: 1rem;
    width: 100%;
    margin-top: 1rem;
  }

  .carousel-description {
    padding-top: 2rem;
    font-size: 22px;
    padding-bottom: 8rem;
  }

  .carousel-footer {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 8rem;
  }

  .carousel-footer-authors {
    text-align: center;
  } 

  .footer-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .footer-content {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .footer-content:before {
    position: absolute;
    top: 0;
    left: 0;
  
  }

  .footer-text-container {
    overflow: hidden;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    height: fit-content;
    color: white;
    text-align: center;
    font-weight: 500;
    line-height: 21px;
    width: 100%;
  }
  
  .footer-text {
    margin-top: calc(100vh - 2rem);
    pointer-events: auto;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 13px 30px var(--blue); 
    box-shadow: 0px 0px 13px 30px var(--blue);
    background-color: var(--blue);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-text p {
    max-width: 1100px;
    padding: 0;
    margin: 0;
    font-size: 15px;
    padding-bottom: 1rem;
  }


  .project-titles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: center;
    padding-top: 2rem;
  }

  .project-title {
    height: 100px;
    max-width: 900px;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: -1rem;
    transition: transform 500ms ease-in-out;
  }

  .project-title:hover {
    transform: rotate(3deg);
  }
}

.home::-webkit-scrollbar{
  display: none;
}

@media screen and (max-width: 800px) {
.home {

  height: 100%;
  .project-title {
    height: unset;
    min-height: 50px;
    width: 90%;
    object-fit: contain;
    cursor: pointer;
    margin-bottom: -1rem;
  }

  .footer-text {
    font-size: 11px;
    line-height: unset;
    padding: 0 0.25rem;
    margin-top: calc(100vh - 4.5rem);
  }

  .flying-igor-image {
    height: 100px;
  }


  .carousel {
    left:0;
    width: 100%;
  }

  .carousel-content {
    padding-top: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .carousel-x {
    position: fixed;
    top: calc(100vh - 10rem);
    left: 0rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .carousel-footer {
    padding-bottom: 8rem;
  }

  .carousel-title {
    max-height:  35px;
    width: calc(100% - 4rem);
    left: 2rem
  }
}
}


@media screen and (max-width: 800px) {
  .cyclobrowsing {
    .carousel-mobile {
      display: block;
      margin-left: -1rem;
      margin-right: -1rem;
    }
  
    .carousel-mobile img {
      width: 100%;
    }
  
    .carousel {
      display: none;
    }
    .footer.mobile {
      display: block;
    }
    .footer.desktop {
      display: none;
    }
  
    .footer {
      height: 2.5rem;
      justify-content: center;
      z-index: 3;
      background-color: transparent;
      width: auto;
      color: black;
    }
  
    .info-content-text:active {
      background-color: rgba(255, 255, 255, 0.9);
    }
  
    .info-container:hover {
      background-color: transparent;
    }
  
    .footer-text {
      font-size: 10px;
    }
  
    .map {
      height: 100vh;
      position: fixed;
      top: 0;
    }
  
    .title {
      position: fixed;
      width: 100%;
      left: 0;
      top: 0;
      font-size: 65px;
      text-align: center;
    }
  
    .logo {
      display: none;
    }
  
    .info-container {
      pointer-events: none;
    }
  
    .info-content {
      font-size: 10px;
      position: relative;
      width: 100%;
      left: 0;
      pointer-events: none;
    }
  
    .stats {
      width: 100%;
      min-width: 0;
    }
  
    .info-content-text {
      margin-top: 60vh;
      padding-bottom: 1rem;
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 5rem;
      transition: background-color 500ms;
      pointer-events: auto;
    }
  }
}